import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Muscle Snatch 4×4`}</p>
    <p>{`Full Snatch 4×6\\@70%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10-8-6-4-2: OHS (95/65)(RX+ 115/75)`}</p>
    <p>{`20-18-16-14-12: Wall Balls (20/14)(RX+ 30/20)`}</p>
    <p>{`10-8-6-4-2: Power Snatch (95/65))RX+ 115/75)`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`(sets go: 10/20/10, 8/18/8, 6/16/6, 4/14/4, 2/12/2)`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      